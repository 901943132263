import { lazy } from 'react'
import locales from './locales'
import routes from './routes'
import themes from './themes'
import parseLanguages from 'base-shell/lib/utils/locale'
import { dark } from '@material-ui/core/styles/createPalette'
import jwt from 'jsonwebtoken'

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _Loading = _interopRequireDefault(require("../components/Loading"));
var payload = {
  resource: { dashboard: 2 },
  params: {},
  exp: Math.round(Date.now() / 1000) + (10 * 60) // 10 minute expiration
};
var token = jwt.sign(payload, "8dd92c627ed5588c91c20450c929310b487ffbcb4b7b116d971829dccc8d69c9");//process.env.REACT_APP_METABASE_SECRET_KEY

const config = {
  auth: {
    signInURL: '/signin',
  },
  routes,
  locale: {
    locales,
    defaultLocale: parseLanguages(['ru', 'en', 'de'], 'en'),
    // defaultLocale: parseLanguages('ru'),
    onError: (e) => {
      //console.warn(e)
      return
    },
  },
  components: {
    Loading: 'true',
  },
  menu: {
    // width: 280,

    // offlineIndicatorHeight: 12,
    // initialAuthMenuOpen: true,
    // initialMiniMode: true,
    // initialMenuOpen: true,
    // initialMobileMenuOpen: true,
    // initialMiniSwitchVisibility: true,

    MenuHeader: lazy(() => import('../components/Menu/MenuHeader')),
    MenuContent: lazy(() => import('../components/Menu/MenuContent')),
  },
  theme: {
    themes,
    mode: dark,
    defaultThemeID: 'default',
    defaultIsDarkMode: false,
    defaultIsRTL: false, //change this to true for default Right to Left Language support
  },
  pages: {
    LandingPage: lazy(() => import('../pages/LandingPage/LandingPage')),
    PageNotFound: lazy(() => import('../pages/PageNotFound/PageNotFound')),
  },
  responseTimeout: 30000,

   remote: {
     isEnabled: true, // флаг переключения между фиктивным и реальным сервером
     url: process.env.REACT_APP_BASE_API_URL // URL реального сервера
   },
   dashboardUrl: "https://mtb.otk.armet.pro" + "/embed/dashboard/" //+ token + "#bordered=true&titled=true&refresh=60"

   //process.env.REACT_APP_METABASE_SITE_URL
}

export default config
