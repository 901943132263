import axios from 'axios';
// import { getCookie } from '../routes/utils';
// import { JWT_COOKIE, REFRESH_EXPIRE_COOKIE } from '../routes/constants';

export const HttpStatus = {
  OK: 200,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  INTERNAL_SERVER_ERROR: 500
};

export const REFRESH_URL = 'users/token/refresh';
export const LOGIN_URL = process.env.REACT_APP_BASE_API_URL+'/users/login';
export const LOGOUT_URL = 'users/logout';

export const IGNORE_URLS = [LOGIN_URL, LOGOUT_URL];

export const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  withCredentials: true
});

// export const handleRefreshToken = async () => {
//   const username = localStorage.getItem('username');
//   const jwtToken = getCookie(JWT_COOKIE);
//   const refreshTokenExpire = getCookie(REFRESH_EXPIRE_COOKIE);
//   if (!jwtToken && username && refreshTokenExpire) {
//     await axios.post(
//       `${process.env.REACT_APP_BASE_API_URL}${REFRESH_URL}`,
//       { username },
//       { withCredentials: true }
//     );
//   }
// };

// axiosClient.interceptors.request.use(
//   async config => {
//     const { url } = config;
//     if (IGNORE_URLS.includes(url as string)) {
//       return config;
//     }
//     await handleRefreshToken();
//     return config;
//   },
//   error => {
//     return Promise.reject(error);
//   }
// );

axiosClient.interceptors.response.use(
  response => response,
  error => {
    if (error?.response?.status === HttpStatus.UNAUTHORIZED) {
      window.location.href = '/home';
    }
    return Promise.reject(error);
  }
);
