/* eslint-disable react/jsx-key */
import React, { lazy } from 'react'
import AuthorizedRoute from 'base-shell/lib/components/AuthorizedRoute/AuthorizedRoute'
import UnauthorizedRoute from 'base-shell/lib/components/UnauthorizedRoute/UnauthorizedRoute'
import { Route } from 'react-router-dom'

const SignIn = lazy(() => import('../pages/SignIn/SignIn'))
const SignUp = lazy(() => import('../pages/SignUp/SignUp'))
const PasswordReset = lazy(() => import('../pages/PasswordReset/PasswordReset'))
const About = lazy(() => import('../pages/About'))
const Home = lazy(() => import('../pages/Home/Home'))

const Users = lazy(() => import('../pages/UsersPage/index'))
const User = lazy(() => import('../pages/UsersPage/User'))
const MyAccount = lazy(() => import('../pages/MyAccount/MyAccount'))
const Roles = lazy(() => import('../pages/Roles/index'))
const Role = lazy(() => import('../pages/Roles/Role'))

const Elements = lazy(() => import('../pages/Elements/index'))
const Element = lazy(() => import('../pages/Elements/Element'))

const Materials = lazy(() => import('../pages/Materials/index'))
const Material = lazy(() => import('../pages/Materials/Material'))

const Products = lazy(() => import('../pages/Products/index'))
const Product = lazy(() => import('../pages/Products/Product'))
const Category = lazy(() => import('../pages/Products/Category'))

const Factories = lazy(() => import('../pages/Factories/index'))
const Factory = lazy(() => import('../pages/Factories/Factory'))

const Stocks = lazy(() => import('../pages/Stocks/index'))
const Stock = lazy(() => import('../pages/Stocks/Stock'))

const Modifiers = lazy(() => import('../pages/Modifiers/index'))
const Modifier = lazy(() => import('../pages/Modifiers/Modifier'))

const Brends = lazy(() => import('../pages/Brends/index'))
const Brend = lazy(() => import('../pages/Brends/Brend'))

const Orders = lazy(() => import('../pages/Orders/index'))
const Order = lazy(() => import('../pages/Orders/Order'))

const WorkOrders = lazy(() => import('../pages/WorkOrders/index'))
const WorkOrder = lazy(() => import('../pages/WorkOrders/WorkOrder'))
const WorkOrderPreview = lazy(() => import('../pages/WorkOrders/WorkOrderPreview'))

const FinalProducts = lazy(() => import('../pages/FinalProducts/index'))
const FinalProduct = lazy(() => import('../pages/FinalProducts/FinalProduct'))
const FinalProductPreview = lazy(() => import('../pages/FinalProducts/FinalProductPreview'))
const FinalProductArchive = lazy(() => import('../pages/FinalProducts/Archive'))

const FinalFactoryProducts = lazy(() => import('../pages/FinalFactoryProducts/index'))
const FinalFactoryDeliveryProducts = lazy(() => import('../pages/FinalFactoryProducts/FactoryDelivery'))
const FinalFactoryInstalledProducts = lazy(() => import('../pages/FinalFactoryProducts/FactoryInstalled'))
const FinalFactoryProduct = lazy(() => import('../pages/FinalFactoryProducts/FinalFactoryProduct'))
const FinalFactoryProductPreview = lazy(() => import('../pages/FinalFactoryProducts/FinalFactoryProductPreview'))
const FinalFactoryArchiveProduct = lazy(() => import('../pages/FinalFactoryProducts/FactoryArchive'))

// Engeneer and Factory role
const Installers = lazy(() => import('../pages/Installers/index'))
const Installer = lazy(() => import('../pages/Installers/Installer'))

const Devices = lazy(() => import('../pages/Devices/index'))
const Device = lazy(() => import('../pages/Devices/Device'))
const DeviceCategory = lazy(() => import('../pages/Devices/DeviceCategory'))

const Customers = lazy(() => import('../pages/Customers/index'))
const Customer = lazy(() => import('../pages/Customers/Customer'))

// const Companies = lazy(() => import('../pages/Companies'))
// const Company = lazy(() => import('../pages/Companies/Company'))
// const Tasks = lazy(() => import('../pages/Tasks'))
// const Task = lazy(() => import('../pages/Tasks/Task'))

const routes = [
  <UnauthorizedRoute path="/signin" redirectTo="/" exact component={SignIn} />,
  <UnauthorizedRoute path="/signup" redirectTo="/" exact component={SignUp} />,
  <UnauthorizedRoute
    path="/password_reset"
    redirectTo="/"
    exact
    component={PasswordReset}
  />,
  <Route path="/about" exact component={About} />,
  <AuthorizedRoute path="/my_account" exact component={MyAccount} />,
  <AuthorizedRoute path="/home" exact component={Home} />,
  
  <AuthorizedRoute path="/users" exact component={Users} />,
  <AuthorizedRoute path="/users/:uid" exact component={User} />,
  <AuthorizedRoute path="/create_user" exact component={User} />,

  <AuthorizedRoute path="/roles" exact component={Roles} />,
  <AuthorizedRoute path="/roles/:uid" exact component={Role} />,
  <AuthorizedRoute path="/create_role" exact component={Role} />,

  <AuthorizedRoute path="/elements" exact component={Elements} />,
  <AuthorizedRoute path="/elements/:uid" exact component={Element} />,
  <AuthorizedRoute path="/create_element" exact component={Element} />,

  <AuthorizedRoute path="/materials" exact component={Materials} />,
  <AuthorizedRoute path="/materials/:uid" exact component={Material} />,
  <AuthorizedRoute path="/create_material" exact component={Material} />,

  <AuthorizedRoute path="/products" exact component={Products} />,
  <AuthorizedRoute path="/products/:uid" exact component={Product} />,
  <AuthorizedRoute path="/create_product" exact component={Product} />,
  <AuthorizedRoute path="/category/:uid" exact component={Category} />,
  <AuthorizedRoute path="/create_category" exact component={Category} />,

  <AuthorizedRoute path="/factories" exact component={Factories} />,
  <AuthorizedRoute path="/factories/:uid" exact component={Factory} />,
  <AuthorizedRoute path="/create_factory" exact component={Factory} />,

  <AuthorizedRoute path="/stocks" exact component={Stocks} />,
  <AuthorizedRoute path="/stocks/:uid" exact component={Stock} />,
  <AuthorizedRoute path="/create_stock" exact component={Stock} />,

  <AuthorizedRoute path="/modifiers" exact component={Modifiers} />,
  <AuthorizedRoute path="/modifiers/:uid" exact component={Modifier} />,
  <AuthorizedRoute path="/create_modifier" exact component={Modifier} />,

  <AuthorizedRoute path="/brends" exact component={Brends} />,
  <AuthorizedRoute path="/brends/:uid" exact component={Brend} />,
  <AuthorizedRoute path="/create_brend" exact component={Brend} />,

  <AuthorizedRoute path="/ordersList" exact component={Orders} />,
  <AuthorizedRoute path="/orders/:uid" exact component={Order} />,
  <AuthorizedRoute path="/create_order" exact component={Order} />,

  <AuthorizedRoute path="/workOrdersList" exact component={WorkOrders} />,
  <AuthorizedRoute path="/workOrders/:uid" exact component={WorkOrderPreview} />,
  <AuthorizedRoute path="/create_workOrder" exact component={WorkOrder} />,

  <AuthorizedRoute path="/finalProductList" exact component={FinalProducts} />,
  <AuthorizedRoute path="/finalProduct/:uid" exact component={FinalProduct} />,
  <AuthorizedRoute path="/finalProductPreview/:uid" exact component={FinalProductPreview} />,
  // <AuthorizedRoute path="/finalProduct/:uid" exact component={FinalProductPreview} />,
  <AuthorizedRoute path="/finalProductArchive" exact component={FinalProductArchive} />,

  <AuthorizedRoute path="/finalFactoryDeliveryList" exact component={FinalFactoryDeliveryProducts} />,
  <AuthorizedRoute path="/finalFactoryProductList" exact component={FinalFactoryProducts} />,
  <AuthorizedRoute path="/finalFactoryInstalledList" exact component={FinalFactoryInstalledProducts} />,
  <AuthorizedRoute path="/finalFactoryArchiveProduct" exact component={FinalFactoryArchiveProduct} />,
  <AuthorizedRoute path="/finalFactoryProduct/:uid" exact component={FinalFactoryProduct} />,
  <AuthorizedRoute path="/finalFactoryProductPreview/:uid" exact component={FinalFactoryProductPreview} />,
  // <AuthorizedRoute path="/finalProduct/:uid" exact component={FinalProductPreview} />,
  

  // Engeneer and Factory role
  <AuthorizedRoute path="/installers" exact component={Installers} />,
  <AuthorizedRoute path="/installers/:uid" exact component={Installer} />,
  <AuthorizedRoute path="/create_installer" exact component={Installer} />,
  
  <AuthorizedRoute path="/devices" exact component={Devices} />,
  <AuthorizedRoute path="/devices/:uid" exact component={Device} />,
  <AuthorizedRoute path="/create_device" exact component={Device} />,
  <AuthorizedRoute path="/device_category/:uid" exact component={DeviceCategory} />,
  <AuthorizedRoute path="/create_device_category" exact component={DeviceCategory} />,
  
  <AuthorizedRoute path="/customers" exact component={Customers} />,
  <AuthorizedRoute path="/customers/:uid" exact component={Customer} />,
  <AuthorizedRoute path="/create_customer" exact component={Customer} />,
  
]

export default routes
