import common from '@material-ui/core/colors/common'
import red from '@material-ui/core/colors/red'
import green from '@material-ui/core/colors/green'
import orange from '@material-ui/core/colors/orange'

const themes = [
  // {
  //   id: 'default',
  //   color: common.white,
  //   source: {
  //     palette: {
  //       primary: orange,
  //       secondary: green,
  //       error: red,
  //     },
  //   },
  // }
  {
    id: 'default',
    color: common.white,
    source: {
      palette: {
        primary: { 
          light: orange[300],      
          main: orange[500],
          dark: orange[700],
          contrastText: '#ffffff',
          
        },
        secondary: {
          main: green[500],
          contrastText: '#ffffff',
        },
      },
    },
  },
]

export default themes
